window.$ = window.jQuery = require('jquery');
var slick = require('slick-carousel');

require('./bootstrap');
require('../../public/vendor/gdpr/gdpr-cookie.js');

window.Vue = require('vue');


const app = new Vue({
    el: '#app'
});


$(document).ready(function() {

    var ga_id = $('body').attr('data-gaid');

    // --- Leaflet map
    if ($("#embedMap").length > 0) {
        var mymap = L.map('embedMap').setView([47.81769092580021, 20.338344616666408], 9);

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 19
        }).addTo(mymap);

        /* var koronaIcon = L.Icon.extend({
             options: {
                 shadowUrl: '/img/marker.png',
                 iconSize:     [38, 95],
                 shadowSize:   [50, 64],
                 iconAnchor:   [22, 94],
                 shadowAnchor: [4, 62],
                 popupAnchor:  [-3, -76]
             }
         });*/

        /*var MyIconClass = L.Icon.extend({
            iconAnchor: new L.Point(16, 16),
            iconUrl: '/img/marker.png',
        });*/

        var koronaIcon = L.Icon.extend({
            options: {
                iconUrl: '/img/marker.png',
                iconSize: [36, 45],
                iconAnchor: [20, 0]
            }
        });

        var goldIcon = new koronaIcon({
            iconUrl: '/img/marker.png'
        })

        L.marker([47.81769092580021, 20.338344616666408], { icon: goldIcon }).addTo(mymap)
            .bindPopup("<a href='https://goo.gl/maps/EMgXZVEVHpvWdRH99' target='_blank'>Egri Korona Borház, Borfalu és Wellness Hotel</a>").openPopup();

    }

    $(window).on("unload", function(e) {
        // On navigating away from page
        hideNav();
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            if (typeof ca[i] != 'undefined' && typeof ca != 'undefined') {
                var c = $.trim(ca[i]);
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    var popupCookie = 'notificationShown' + $('.popup-container').attr('data-version');

    // --- Notification popup
    function showNotification() {
        if ($('.popup').length > 0) {
            setTimeout(function() {
                if (getCookie(popupCookie) == "" || $('.popup').hasClass('forceVisible')) {
                    $('.popup').addClass('is-visible');
                }
            }, 1000);
            $('.popup').on('click', function(event) {
                if ($(event.target).is('.popup-close') || $(event.target).is('.popup')) {
                    event.preventDefault();
                    $(this).removeClass('is-visible');
                    setCookie(popupCookie, 'popped', 30);
                }
            });
            //close popup when clicking the esc keyboard button
            $(document).keyup(function(event) {
                if (event.which == '27') {
                    $('.popup').removeClass('is-visible');
                    setCookie(popupCookie, 'popped', 30);
                }
            });
        }
    }

    if ($(".agePopup").length == 0) {
        showNotification();
    }

    // --- Age restriction popup
    $("#yes18").on("click", function(e) {
        e.preventDefault();
        $(".agePopup").fadeOut(1000);
        setCookie('is18', '1', 5000);
        showNotification();
    });
    $("#no18").on("click", function(e) {
        e.preventDefault();
        window.location.replace("/");
    });


    // --- Reserve form submit
    /*$("#reserveForm").on('submit', function(e) {
        e.preventDefault();
        if ($(this).hasClass('inprogress')) {
            return false;
        }
        // --- Validate form
        let isValid = true;
        if (!$("#reserveForm input[name='name']").val()) {
            isValid = false;
            $("#reserveForm input[name='name']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        }
        if (!$("#reserveForm input[name='email']").val()) {
            isValid = false;
            $("#reserveForm input[name='email']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        } else if (!validateEmail($("#reserveForm input[name='email']").val())) {
            isValid = false;
            $("#reserveForm input[name='email']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Érvénytelen e-mail cím!</span>'));
        }
        if (!$("#reserveForm input[name='phone']").val()) {
            isValid = false;
            $("#reserveForm input[name='phone']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        }
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        let arrival_date = new Date($("#reserveForm input[name='arrival_date']").val());
        arrival_date.setHours(0, 0, 0, 0);
        let leaving_date = new Date($("#reserveForm input[name='leaving_date']").val());
        leaving_date.setHours(0, 0, 0, 0);
        if (!$("#reserveForm input[name='arrival_date']").val()) {
            isValid = false;
            $("#reserveForm input[name='arrival_date']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        } else if (Date.parse(arrival_date) <= Date.parse(now)) {
            isValid = false;
            $("#reserveForm input[name='arrival_date']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Érvénytelen dátum!</span>'));
        }
        if (!$("#reserveForm input[name='leaving_date']").val()) {
            isValid = false;
            $("#reserveForm input[name='leaving_date']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        } else if (Date.parse(arrival_date) >= Date.parse(leaving_date)) {
            isValid = false;
            $("#reserveForm input[name='leaving_date']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Érvénytelen dátum!</span>'));
        }
        if (!$("#reserveForm input[name='visitor_adult_count']").val()) {
            isValid = false;
            $("#reserveForm input[name='visitor_adult_count']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező mező!</span>'));
        }
        if ($('#reserveForm #agreeTerms').is(':checked') != true) {
            isValid = false;
            $("#reserveForm input[name='agreeTerms']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">Kötelező elfogadni!</span>'));
        }
        // --- Send data
        if (isValid) {
            $(this).addClass('inprogress');
            $("#reserveForm .sendReserve").addClass('loading');
            $.ajax({
                type: "POST",
                url: '/foglalas',
                data: $("#reserveForm").serialize(),
                success: function(data) {
                    $("#reserveForm").removeClass('inprogress');
                    $("#reserveForm .sendReserve").removeClass('loading');
                    if (data.success) {
                        let reserveType = $("#reserveForm select[name='type']").val();
                        $("#reserveForm").fadeOut(250, function() {
                            $("#reserveSuccess span.typeSpan").text(reserveType);
                            $("#reserveSuccess").fadeIn(250);
                            $('<svg id="40d74f06-7bcb-4fa8-83d7-fe4849e923a7" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" ><defs><style>.b3923ed5-d817-4490-83ed-173754ea24b3{fill:#231f20;}</style><title>checkmark</title><g id="circlegroup"><path id="circle" class="circle-shape" d="M172.93,113.49a77.45,77.45,0,1,1-25.12-28.84l17.71-15.9a100.68,100.68,0,1,0,25.24,28.74Z" transform="translate(-3 -10.83)"/><path id="check" class="check-shape" d="M52.82,141.33l27-27L104.4,148.4s74-93.06,164.45-137.57c0,0-111.83,86.66-168.93,194.83C99.92,205.66,72.19,162.9,52.82,141.33Z" transform="translate(-3 -10.83)"/></g></defs></svg><svg id="Logo" class="Animate-Path" width="100%" height="100%" viewBox="0 0 612 792" xml:space="preserve"><use id="Draw-Circle" class="Animate-Draw" xlink:href="#circle" /><use id="Draw-Check" class="Animate-Draw" xlink:href="#check" /></svg>').prependTo("#reserveSuccess .checkmark");
                        });
                        // Send analytics event
                        window.dataLayer = window.dataLayer || [];

                        function gtag() { dataLayer.push(arguments); }
                        if (reserveType == 'Ajánlatkérés') {
                            parent.location.hash = "sikeres-ajanlatkeres";
                            gtag('config', ga_id, {
                                'page_title': 'Sikeres ajánlatkérés!',
                                'page_path': '/hu/sikeres-ajanlatkeres'
                            });
                            gtag('event', 'Űrlapkitöltés', {
                                'event_category': 'Szállásfoglalás',
                                'event_label': 'Ajánlatkérés'
                            });
                            window.history.pushState('sikeres-ajanlatkeres', 'Sikeres ajánlatkérés!', '/hu/sikeres-ajanlatkeres');
                        } else {
                            parent.location.hash = "sikeres-szallasfoglalas";
                            gtag('config', ga_id, {
                                'page_title': 'Sikeres szállásfoglalás!',
                                'page_path': '/hu/sikeres-szallasfoglalas'
                            });
                            gtag('event', 'Űrlapkitöltés', {
                                'event_category': 'Szállásfoglalás',
                                'event_label': 'Foglalás'
                            });
                            window.history.pushState('sikeres-szallasfoglalas', 'Sikeres szállásfoglalás!', '/hu/sikeres-szallasfoglalas');
                        }
                    } else {
                        $.each(data.errors, function(key, errorMsg) {
                            $("#reserveForm input[name='" + key + "']").addClass('invalid').closest(".inputGroup").append($('<span class="errorMsg">' + errorMsg + '</span>'));
                        });
                    }
                }
            });
        } else {
            console.log("Form errors found...");
        }
        return false;
    });*/

    function validateEmail($email) {
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,10})?$/;
        return emailReg.test($email);
    }

    $('body').on('keyup change focus', '.invalid', function(e) {
        $(this).removeClass('invalid');
        $(this).closest(".inputGroup").find("span.errorMsg").fadeOut(300, function() { $(this).remove(); });
    });

    // --- Fancybox gallery
    if ($('.gallery-container').length > 0) {
        $(".fancybox").fancybox({
            animationEffect: 'fade',
        }).attr('data-fancybox', 'group1');
    }

    // --- CHECKOUT functions

    let pickpackData = false;

    // Select shipping method
    $("input[name='shipping_method']").on('change', function() {
        let shipping_method = $("input[name='shipping_method']:checked").val();
        if (shipping_method == 'delivery') {
            showHomeDeliveryOptions();
            $("#pickpackData").slideUp(500);
            calculateShippingCost();
        } else {
            hideHomeDeliveryOptions();
            showPickPackInput();
        }
    });

    // Show home delivery options
    function showHomeDeliveryOptions() {
        $("#homeDelivery").slideDown(750);
    }

    // Hide home delivery options
    function hideHomeDeliveryOptions() {
        $("#homeDelivery").slideUp(750);
    }

    // Show Pick Pack Input
    function showPickPackInput() {
        showBlocker();
        $("#pickpackSelector").addClass('visible');
        $('body').addClass('locked');
        $("#pickpackData").slideDown(750);
    }

    // Hide Pick Pack Input
    function hidePickPackInput() {
        hideBlocker();
        $("#pickpackSelector").removeClass('visible');
        $('body').removeClass('locked');
    }

    // Select pick pack point
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
        if(event.data == 'recaptcha-setup') {
            return;
        }
        hidePickPackInput();
        pickpackData = event.data;
        showSelectedPickPack();
        calculateShippingCost();
    };

    // Show details from the selected pickpack input
    showSelectedPickPack = function() {
        if($("#pickpackData").length > 0) {
            if (pickpackData) {
                $("#pickpackData").removeClass('unselected').addClass('selected');
                let parseData = JSON.parse(pickpackData);
                $("#pickpackData .selected p.name").text(parseData.shopName);
                $("#pickpackData .selected p.address").text(parseData.address);
                $("input[name='pickpack_shopCode']").val(parseData.shopCode);
                console.log(parseData.shopCode);
            } else {
                $("#pickpackData").addClass('unselected').removeClass('selected');
            }
        }
    }

    // Make checkout-table-container fixed

    // Manually reopen pickpackpoint input
    $("#pickpackData").on('click', function() {
        showPickPackInput();
    })

    // Calculate shippingcost
    function calculateShippingCost() {
        let shipping_method = $("input[name='shipping_method']:checked").val();
        if (shipping_method == 'delivery') {
            // --- Show delivery shipping cost
            let bottleCount = $("input[name='cartBottleCount']").val();
            let shippingCost = parseInt(3500 * 1.27);
            if (bottleCount >= 18) {
                shippingCost = 0;
            }
            let subtotal = $("input[name='subtotal']").val();
            $("#shippingDisplay").text(priceFormat(shippingCost));
            $("#totalDisplay").text(priceFormat(parseInt(shippingCost) + parseInt(subtotal)));
            $(".shipping-total").fadeIn();
            $("input[name='shipping_total']").val(shippingCost);
        } else if (shipping_method == 'pickpackpont') {
            // --- Show pickpack shipping cost
            let shippingCost = 950 + ($("#totalQuantity").val() * 75); // rough estimate
            if (shippingCost > 2500) {
                shippingCost = 2500;
            }
            let subtotal = $("input[name='subtotal']").val();
            $("#shippingDisplay").text(priceFormat(shippingCost));
            $("#totalDisplay").text(priceFormat(parseInt(shippingCost) + parseInt(subtotal)));
            $(".shipping-total").fadeIn();
            $("input[name='shipping_total']").val(shippingCost);
        } else {
            $(".shipping-total").hide();
        }
    }

    function priceFormat(nStr) {
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }
        return x1 + x2 + " Ft";
    }

    // Fill out city based on zipcode
    if ($('.zipcodeInput').length > 0) {
        $('.zipcodeInput').on('change', function() {
            let zipcode = $(this).val();
            let my_zipcode = window.zipcodes[zipcode];
            let city_input = $('input#' + $(this).attr('data-city'));
            if (typeof my_zipcode === 'undefined') {
                city_input.val('(érvénytelen irányítószám)');
            } else {
                city_input.val(my_zipcode['city']);
                if ($(this).attr('name') == 'shipping_postalcode' || $('#shippingSame').is(':checked')) {
                    calculateShippingCost();
                    /*// Is there delivery to this location?
                    if(my_zipcode['delivery_enabled'] == 0) {
                      // Nope.
                      //alert('A kiválasztott területre jelenleg nem tudunk szállítást vállalni.');
                      // Hide delivery cost
                      $(".shipping-on").hide();
                      $(".shipping_cost").hide();
                    } else {
                      // Yes, show delivery cost
                      $(".shipping_cost").text(my_zipcode['delivery_cost']);
                      $(".shipping-on").fadeIn(500);
                      $(".shipping_cost").fadeIn(500);
                      // Add delivery cost to total
                      //let total = parseInt($(".checkout-totals").attr("data-subtotal")) + parseInt(my_zipcode['delivery_cost']);
                      //$(".total_cost").text(priceFormat(total));

                    }*/
                }
            }

        });

        if ($('.zipcodeInput').val()) {
            $('.zipcodeInput').trigger('change'); // Value of previous order
        }
    }

    // --- If there is only one selectable shipping method, activate it
    if ($(".shipping_method.radio_buttons .option").length == 1) {
        $(".shipping_method.radio_buttons .option input").prop('checked', true);
        $("input[name='shipping_method']").change();
    }

    // --- Submitting payment form
    $('#payment-form').on('submit', function(e) {
        if ($('#shippingSame').is(':checked')) {
            $("#shipping_name").val($("#name").val());
            $("#shipping_postalcode").val($("#postalcode").val());
            $("#shipping_city").val($("#city").val());
            $("#shipping_address").val($("#address").val());
            $("#shipping_floor").val($("#floor").val());
        }
    });

    $('#shippingSame').on('change', function() {
        if ($(this).is(':checked')) {
            // Szallitasi cim megegyezik a szamlazasival
            $("#deliveryInputs").slideUp();
        } else {
            // Szallitasi cim nem egyezik meg
            $("#deliveryInputs").slideDown();
            $("#shipping_name").val("");
            $("#shipping_postalcode").val("");
            $("#shipping_city").val("");
            $("#shipping_address").val("");
            $("#shipping_floor").val("");
        }
    });

    $('#tax_as_company').on('change', function() {
        if ($(this).is(':checked')) {
            $(".company-inputs").slideDown();
        } else {
            $(".company-inputs").slideUp();
        }
    });

    // --- Quantity input
    $('body').on('click', '.quantityInput .increment', function(e) {
        e.preventDefault();
        let quantity = parseInt($('.quantityInput .counter span').text());
        if ($(this).hasClass('minus') && (quantity > 1)) {
            quantity--;
        } else if ($(this).hasClass('plus') && (quantity < 99)) {
            quantity++;
        }
        $('.quantityInput .counter span').text(quantity);
        $('.addToCart').attr('data-quantity', quantity);
    });

    // --- Remove element from cart
    $('body').on('click', '.removeFromCart', function(e) {
        e.preventDefault();
        $(this).parent().addClass('deleted').slideUp(500);
        recalculateCart();
        // --- Send ajax request to backend   -- TODO: This should run first before feedback
        let deleteUrl = $(this).attr("data-url");
        $.ajax({
            type: "POST",
            url: deleteUrl,
            data: '',
            type: 'DELETE'
        }).done(function() {});
    });

    // --- Recalculate cart price, and whether it's empty or not
    function recalculateCart() {
        let cartCount = 0;
        let cartTotalPrice = 0;
        $.each($("#cart .cartBox"), function(key, cartElement) {
            if ($(cartElement).hasClass('deleted')) {
                return;
            }
            cartCount++;
            cartTotalPrice += parseInt($(cartElement).attr('data-price'));
        });
        $("#cartTotal").text(cartTotalPrice);
        if (cartCount == 0) {
            $("#cart").addClass('empty');
            $("#basketCount").text('');
        } else {
            $("#cart").removeClass('empty');
            $("#basketCount").text(cartCount);
        }
    }

    // --- Open profile submenu
    $(".triggerProfile").on('click', function(e) {
        e.preventDefault();
        $("#profileMenu").toggleClass('visible');
        return false;
    });

    // --- Populate cart
    function populateCart(cartArray) {
        $("#cart .cartBody").html('');
        let cartCount = 0;
        let cartTotalPrice = 0;
        $.each(cartArray, function(key, cartElement) {
            let price = (cartElement['price'] * cartElement['qty']);
            let cartBox = '<div class="cartBox" data-price="' + price + '">' +
                '<div class="img" style="background-image: url(\'' + cartElement['img'] + '\')"></div>' +
                '<div class="text">' +
                '<p>' + cartElement['name'] + '</p>' +
                '<div class="sep"></div>' +
                '<div class="qty"><span>' + cartElement['qty'] + '</span> palack</div>' +
                '<div class="price">' + price + ' Ft</div>' +
                '</div>' +
                '<div class="removeFromCart" data-url="' + cartElement['deleteUrl'] + '"></div>' +
                '</div>';
            $("#cart .cartBody").append(cartBox);
            cartCount++;
            cartTotalPrice += price;
        });
        $("#cartTotal").text(cartTotalPrice);
        if (cartCount == 0) {
            $("#cart").addClass('empty');
        } else {
            $("#cart").removeClass('empty');
        }
    }
    if (window.myCart) {
        populateCart(window.myCart);
    }

    // --- Open cart sidebar
    $(".closeCart, .openCart").on('click', function(e) {
        e.preventDefault();
        if ($("#cart").hasClass('visible') !== true) {
            if ($("#cart").hasClass('empty') == false) {
                // --- Show cart
                showCart();
                showBlocker();
            } else {
                // --- Empty cart: shake icon
                if ($(".openCart .icon").hasClass('isEmptyWarning') !== true) {
                    $(".openCart .icon").addClass('isEmptyWarning');
                    setTimeout(function() {
                        $(".openCart .icon").removeClass('isEmptyWarning');
                    }, 1000);
                }
            }
        } else {
            hideCart();
            hideBlocker();
        }
        return false;
    });

    var showCart = function() {
        $("#cart").addClass('visible');
        $("#szechenyiLogo").fadeOut(500);
        $(".sidebar.landing .leftBtn").fadeOut(500);
    }

    var hideCart = function() {
        if ($("form#payment-form").length > 0) {
            location.reload();
            return false;
        }
        $("#cart").removeClass('visible');
        $("#szechenyiLogo").fadeIn(500);
        $(".sidebar.landing .leftBtn").fadeIn(500);
    }

    // Add to cart button
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.addToCart').on('click touchend', function(e) {
        e.preventDefault();
        let $btn = $(this);
        if ($($btn).hasClass('loading') || $($btn).hasClass('success')) {
            return false;
        }
        $($btn).addClass('loading');

        let cartUrl = '/kosar/' + $(this).attr('data-productId') + '/' + $(this).attr('data-quantity');
        $.ajax({
            type: "POST",
            url: cartUrl,
            success: function(data) {
                setTimeout(function() {
                    populateCart(data.cart);
                    $($btn).removeClass('loading').addClass('success').append('<div class="checkmark draw"></div>');
                    setTimeout(function() {
                        $($btn).removeClass('success');
                        $($btn).find('.checkmark').fadeOut(250).delay(250).remove();
                    }, 1500);
                    let itemCount = Object.keys(data.cart).length;
                    $("#basketCount").text(itemCount);
                    $(".icons a.basket").fadeOut(100).fadeIn(100).delay(100).fadeOut(100).fadeIn(100).delay(100).fadeOut(100).fadeIn(100);
                }, 500);
            }
        });
    });

    function getPageTitle(title) {
        return 'Korona | ' + title;
    }

    // Transition from product category list to product view
    function showActiveProduct() {
        let mobileView = $(window).width() <= 768;

        if (!mobileView) {
            $("#productView").show().addClass('visible');
        } else {
            $("#productView").fadeIn(250).addClass('visible');
        }
        $("#productView .info .top").hide();
        $("#productView .info .bottom").hide();
        $("#productView .info .top").delay(200).fadeIn(500);
        $("#productView .info .bottom").delay(400).fadeIn(500);

        // Change url to product url
        let url = '/' + $('#webshop').attr('data-lang') + '/' + $('#webshop').attr('data-base') + '/' + $('#webshop').attr('data-category') + '/' + $('.productBox.active').attr('data-slug');
        window.history.pushState("data", "Title", url);
        document.title = getPageTitle($(".productBox.active").attr('data-title'));

        // Transform white background to where the white circle is
        if (!mobileView) {
            let origHeight = $("#productView .white").css('height');
            let origTop = $("#productView .white").css('top');
            let $bgCircle = $('.productBox.active .bgCircle');
            $("#productView .white").css({
                'width': $($bgCircle).width(),
                'height': $($bgCircle).width(),
                'left': $($bgCircle).offset().left,
                'top': $($bgCircle).offset().top,
                'border-radius': '100%'
            });
            $($bgCircle).hide();
            // Animate it back out into the full background
            $("#productView .white").animate({ "width": "100vw", "height": origHeight, "border-radius": "0", "left": "0", "top": origTop }, 350);
        }

        // Spawn product image in product view
        let $tinyImage = $('.productBox.active a.img img');
        let $image = $($tinyImage).clone();
        let $mobileImage = $($tinyImage).clone();
        $("#productView  .image").html($image);
        $("#productView  .image").append('<div class="shadow"></div>');
        // Transform image to where the preview is
        let imageLeft = $($image).offset().left;
        let imageTop = $($image).offset().top;
        let imageWidth = $($image).width();
        $("#productView .image img").css({
            'height': $($tinyImage).height(),
            'left': $($tinyImage).offset().left,
            'top': $($tinyImage).offset().top
        });
        $($tinyImage).hide();
        $("#productView .image img").animate({ "height": "62vh", "left": "0%", "top": "0", "margin-left": "0" }, 350);
        $("#productView .image .shadow").hide();
        $("#productView .image .shadow").delay(200).fadeIn(500);
        $("#productView  .mobileImage").html($mobileImage);

        // Fade out the text in the small box
        $('.productBox.active .data').fadeOut(250);

        // Hide the carousel
        $('section#webshop').addClass('hidden');

        // Add product id to cart button
        $('#productView .addToCart').attr('data-productId', $('.productBox.active').attr('data-id'));
        $('#productView .btnHolder .counter span').text(1);

        // Display product text data
        $("#productView .productName").text($(".productBox.active").attr('data-title'));
        $("#productView .productDetails").html($(".productBox.active .details").html());
        $("#productView .productPrice span").text($(".productBox.active").attr('data-price'));
        $("#productView .description").html($(".productBox.active .productDescription").html());

        // Lock body
        $("body").addClass('locked');
    }

    $('body').on('click touchend', '.productBox, .productBox *', function(e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).closest('.productBox').hasClass('active')) {
            return false;
        }
        if ($("section#webshop").hasClass("searchResults")) {
            // on search results page: redirect to subpage
            window.location.href = $(this).closest('.productBox').attr('data-fullhref');
            return false
        }
        $('.productBox.active').removeClass('active');
        $(this).closest(".productBox").addClass('active');
        showActiveProduct();
        return false;
    });

    // Transition from active product to category list
    function hideActiveProduct() {
        // Change url to category url
        let url = '/' + $('#webshop').attr('data-lang') + '/' + $('#webshop').attr('data-base') + '/' + $('#webshop').attr('data-category');
        window.history.pushState("data", "Title", url);
        document.title = getPageTitle($("#webshop").attr('data-categoryTitle'));

        // Show the carousel
        $('section#webshop').removeClass('hidden');
        $('.productBox.active .data').fadeIn(250);
        $('.productBox.active a.img img').show();
        $('.productBox.active .bgCircle').show();
        $(".productBox.active").removeClass('active');

        // Hide the product view
        $("#productView").fadeOut(500);
        setTimeout(
            function() { $("#productView").removeClass('visible') },
            500);

        // Unlock body
        $("body").removeClass('locked');
        $('html,body').animate({ scrollTop: 0 }, 250);
    }

    if ($('#productView.visible').length > 0) {
        $("body").addClass('locked');
    }

    $('#productView .close').on('click touchend', function(e) {
        e.preventDefault();
        hideActiveProduct();
        return false;
    });

    // --- Products carousel
    if ($('.productCarousel .slider').length > 0) {
        $('.productCarousel .slider').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: false,
            speed: 1500,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        draggable: 'false',
                        swipe: 'false',
                        swipeToSlide: 'false',
                        touchMove: 'false',
                        draggable: 'false',
                        accessibility: 'false',
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    }

    // --- Landing page main carousel
    $('#landingCarousel .carousel').slick({
        slidesToShow: 1,
        dots: true,
        centerMode: true,
        autoplay: true,
        fade: true,
        arrows: false,
        autoplaySpeed: 5000
    });

    // --- Carousel scroll down arrow
    $('#landingCarousel .arrow a').on('click', function(event) {
        event.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: $("section.homeSelector").offset().top
        }, 1600);
        return false;
    });

    // --- Search input

    $('.search-field-container .field').on('keydown', function(event) {
        if ((event.which === 8) && $(this).val() === '') {
            $('body').removeClass('is-type');
        }
    });

    $("#search").on('submit', function(event) {
        event.preventDefault();
        $("#search, #search .field").addClass('submitted');
        window.location.href = $("#search").attr('data-redirect') + "?s=" + $("#search .field").val();
        return false;
    });

    // --- Close circle click animation
    $('.circle').on('click', function() {
        var animClass = $(this).data('animation');
        var removeTime = $(this).data('remove');
        if ($(this).hasClass(animClass)) {
            $(this).removeClass(animClass);
        } else {
            $(this).addClass(animClass);
            if (typeof removeTime != 'undefined') {
                var el = $(this);
                setTimeout(function() {
                    el.removeClass(animClass);
                }, removeTime);
            }
        }
    });

    // --- Close open sidebars by clicking on the blocker
    $("#blocker").on('click', function() {
        hideBlocker();
        if ($("#cart").hasClass('visible')) {
            hideCart();
        }
        if ($("#reserve").hasClass('visible')) {
            hideReserve();
        }
        if ($("#pickpackSelector.visible").length > 0) {
            $("#pickpackSelector").removeClass('visible');
            $('body').removeClass('locked');
            showSelectedPickPack();
        }
    });

    // --- Open reservation form
    /*$("#reserve .leftBtn, .openReserve").on('click', function() {
        if ($("#reserve").hasClass('visible') !== true) {
            showReserve();
            showBlocker();
        } else {
            hideReserve();
            hideBlocker();
        }
    });*/

    var showBlocker = function() {
        $("#blocker").fadeIn(600);
    }

    var hideBlocker = function() {
        $("#blocker").fadeOut(600);
    }

    var showReserve = function() {
        $("#reserve").addClass('visible');
        $("#szechenyiLogo").fadeOut(500);
    }

    var hideReserve = function() {
        $("#reserve").removeClass('visible');
        $("#szechenyiLogo").fadeIn(500);
    }

    var isReserveFormVisible = function() {
        return $("#reserve").hasClass('visible');
    }

    var hideNav = function() {
        // Animate out language selector
        $("#langSelector").fadeOut(800);
        // Hide left navigation elements
        var delay = 0;
        $('#nav .leftCol .element').each(function() {
            var _this = $(this);
            setTimeout(function() {
                _this.animate({
                    left: "-150px",
                    opacity: "0"
                }, 300);
            }, delay * 20);
            delay++;
        });
        // Hide right navigation elements
        var delay = 0;
        $($('#nav .rightCol .element').get().reverse()).each(function() {
            var _this = $(this);
            setTimeout(function() {
                _this.animate({
                    right: "-150px",
                    opacity: "0"
                }, 300);
            }, delay * 20);
            delay++;
        });
        hideReserve();
        hideBlocker();
    }

    var showNav = function() {
        // Animate in language selector
        $("#langSelector").hide();
        setTimeout(function() {
            $("#langSelector").fadeIn(800);
        }, 250);
        // Animate in left navigation elements
        $('#nav .leftCol .element').css({ 'left': '-150px', 'opacity': '0' });
        var delay = 1;
        $($('#nav .leftCol .element').get().reverse()).each(function() {
            var _this = $(this);
            setTimeout(function() {
                _this.animate({
                    left: "0px",
                    opacity: "1"
                }, 200);
            }, delay * 20);
            delay++;
        });
        // Animate in right navigation elements
        $('#nav .rightCol .element').css({ 'right': '-150px', 'opacity': '0' });
        var delay = 0;
        $('#nav .rightCol .element').each(function() {
            var _this = $(this);
            setTimeout(function() {
                _this.animate({
                    right: "0px",
                    opacity: "1"
                }, 300);
            }, delay * 20);
            delay++;
        });
        hideReserve();
        showBlocker();
    }

    $('.offerReserve').on('click touchend', function(e) {
        e.stopPropagation();
        let chosenPackage = $(this).attr('data-package');
        $("#reserveForm #package").val(chosenPackage);
        showBlocker();
        showReserve();
        console.log(chosenPackage);
        return false;
    });

    // --- Close reservation form
    $(".closeReserve").on('click', function() {
        hideReserve();
        hideBlocker();
    })

    if(window.location.hash && (window.location.hash == '#sikeres-szallasfoglalas' || window.location.hash == '#sikeres-ajanlatkeres')) {
        showReserve();
        showBlocker();
        let reserveType = window.location.hash == '#sikeres-szallasfoglalas' ? 'Foglalás' : 'Ajánlatkérés';
        $("#reserveForm").fadeOut(250, function() {
        $("#reserveSuccess span.typeSpan").text(reserveType);
        $("#reserveSuccess").fadeIn(250);
        $('<svg id="40d74f06-7bcb-4fa8-83d7-fe4849e923a7" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" ><defs><style>.b3923ed5-d817-4490-83ed-173754ea24b3{fill:#231f20;}</style><title>checkmark</title><g id="circlegroup"><path id="circle" class="circle-shape" d="M172.93,113.49a77.45,77.45,0,1,1-25.12-28.84l17.71-15.9a100.68,100.68,0,1,0,25.24,28.74Z" transform="translate(-3 -10.83)"/><path id="check" class="check-shape" d="M52.82,141.33l27-27L104.4,148.4s74-93.06,164.45-137.57c0,0-111.83,86.66-168.93,194.83C99.92,205.66,72.19,162.9,52.82,141.33Z" transform="translate(-3 -10.83)"/></g></defs></svg><svg id="Logo" class="Animate-Path" width="100%" height="100%" viewBox="0 0 612 792" xml:space="preserve"><use id="Draw-Circle" class="Animate-Draw" xlink:href="#circle" /><use id="Draw-Check" class="Animate-Draw" xlink:href="#check" /></svg>').prependTo("#reserveSuccess .checkmark");
        });
    }

    // --- TriCarousel
    if ($('.triCarousel .carousel').length > 0) {
        $('.triCarousel .carousel').slick({
            dots: false,
            arrows: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '80px',
            variableWidth: true,
            autoplay: false,
            autoplaySpeed: 6500,
            responsive: [{
                breakpoint: 992,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    variableWidth: false
                }
            }, {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }]
        });
        $('.triCarousel .carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.triCarousel .carousel .slide.active .slideText .header, .triCarousel .carousel .slide.active .slideText .body, .triCarousel .carousel .slide.active  .reserveButton').fadeOut(500);
            $('.triCarousel .carousel .slide.active').removeClass('active');
            $('.triCarousel .carousel .slide[data-index="' + nextSlide + '"]').addClass('active');
            $('.triCarousel .carousel .slide[data-index="' + nextSlide + '"] .slideText .header, .triCarousel .carousel .slide[data-index="' + nextSlide + '"] .slideText .body').show().css({ 'opacity': '0', 'left': '-50px' });
            setTimeout(function() {
                $('.triCarousel .carousel .slide[data-index="' + nextSlide + '"] .slideText .header').animate({
                    left: "0px",
                    opacity: "1"
                }, 600);
            }, 1000);
            setTimeout(function() {
                $('.triCarousel .carousel .slide[data-index="' + nextSlide + '"] .slideText .body').animate({
                    left: "0px",
                    opacity: "1"
                }, 600);
            }, 1200);
            setTimeout(function() {
                $('.triCarousel .carousel .slide[data-index="' + nextSlide + '"] .reserveButton').fadeIn(600);
            }, 1400);

        });
    }

    // --- Random spacing between words
    /*$('section.squares .textInner h3').each(function() {
        var min = 2;
        var max = 4;
        var newText = $(this).html().replace(/\s/g, function() {
          return " &nbsp; ".repeat(parseInt(Math.random() * (max - min) + min))
        });
      $(this).html(newText)
    });*/

    // --- Hamburger icon

    var $menu = $('#menu');
    var $menuAnchor = $('#menuAnchor');
    var hamburger = $('#menu .menuButton');
    var $logo = $('#mainLogo');
    var $nav = $('#nav');
    hamburger.click(function() {
        hamburger.toggleClass('active');
        $menu.toggleClass('open');
        $logo.toggleClass('hidden');
        $nav.toggleClass('visible');
        if ($nav.hasClass('visible')) {
            showNav();
            $("#menuText").text($("#menuText").attr('data-text-open'));
        } else {
            hideNav();
            $("#menuText").text($("#menuText").attr('data-text-closed'));
        }
        if ($menu.hasClass('sticky')) {
            return;
        }
        if ($menu.hasClass('middle')) {
            var scrollTop = $(window).scrollTop(),
                elementOffset = $menu.offset().top,
                distance = (elementOffset - scrollTop);
            if (hamburger.hasClass('active')) {
                // Push menu button to top of the screen
                // --- Make element fixed with it's current top position
                $menu.css({ 'position': 'fixed', 'top': distance + 'px', 'transform': 'unset' });
                // --- Animate element to top
                $menu.animate({
                    top: "0px"
                }, 500);
            } else {
                // Push menu to it's original location
                var originalLocation = $(window).scrollTop(),
                    elementOffset = $menuAnchor.offset().top,
                    distance = (elementOffset - scrollTop);
                distance -= $menu.height() / 2;
                $menu.animate({
                    top: distance + 'px'
                }, 500, function() {
                    // Turn back menu to absolute positioned on the slider
                    $menu.css({ 'position': 'absolute', 'top': '50%', 'transform': 'translateY(-50%)' });
                });
            }
        }
        return false;
    });

    var checkMenuFixed = function() {
        if ($("body").hasClass("mobile")) {
            return false;
        }
        var $el = $('#menu.middle');
        if ($el) {
            let menuFixedOffset = $(window).height() / 2 - ($("#menu").height() / 2);
            var isPositionFixed = ($el.css('position') == 'fixed');
            if ($(this).scrollTop() > menuFixedOffset && !isPositionFixed) {
                $el.addClass('sticky');
                $('nav.topBar').addClass('visible');
                $('#reserve').addClass('hiddenLanding');
                $('#profileMenu').removeClass('landing').removeClass('visible');
                return true;
            }
            if ($(this).scrollTop() < menuFixedOffset && isPositionFixed) {
                $el.removeClass('sticky');
                $('nav.topBar').removeClass('visible');
                $('#reserve').removeClass('hiddenLanding');
                $('#profileMenu').addClass('landing');
                return false;
            }
        }
    }

    var checkOutTableContainer = function() {
        var $el = $('.checkout-table-container');
        if ($el) {
            let menuFixedOffset = 100;
            if ($(this).scrollTop() > menuFixedOffset) {
                $el.css({ 'top': ($(this).scrollTop() - menuFixedOffset) + 'px' });
            } else {
                $el.css({ 'top': '0px' });
            }
        }
    }

    checkMenuFixed();

    $(window).scroll(function(e) {

        // --- Make menu fixed on scroll
        checkMenuFixed();

        // --- checkout-table-container
        checkOutTableContainer();

    });

    // --- Mobile stuff
    function checkforMobile() {
        var win = $(window);
        //if (win.height() >= 820) { /* ... */ }
        if (win.width() <= 768) {
            $("nav.topBar").addClass('visible');
            $('#menu.middle').addClass('sticky');
            $('#reserve').addClass('hiddenLanding');
            $("body").addClass("mobile");
            var $el = $('#menu.middle');
            if ($el) {
                $('#profileMenu').removeClass('landing');
            }
        } else {
            if ($("body").hasClass("landing") && !checkMenuFixed()) {
                $("nav.topBar").removeClass('visible');
                $('#menu.middle').removeClass('sticky');
                $('#reserve').removeClass('hiddenLanding');
            } else {
                $("nav.topBar").addClass('visible');
            }
            $("body").removeClass("mobile");
            var $el = $('#menu.middle');
            if ($el) {
                $('#profileMenu').addClass('landing');
            }
        }
    }
    $(window).on('resize', function() {
        checkforMobile();
    });

    checkforMobile();


    // --- GDPR COOKIE
    $.gdprcookie.init({
        title: "A weboldal sütiket használ",
        message: "Felhívjuk szíves figyelmét, hogy az oldal további használatával Ön automatikusan hozzájárul a sütik használatához. További információkért tekintse meg az <a href='/hu/adatvedelem' target='_blank'>Adatvédelmi tájékoztatót.</a>",
        delay: 250,
        expires: 1,
        acceptBtnLabel: "Elfogadom",
        advancedBtnLabel: "További beállítások",
        unadvancedBtnLabel: "Vissza",
        subtitle: "Sütik engedélyezése",
        acceptBeforeAdvanced: ["essential", "analytics"],
        cookieTypes: [{
                type: "A weboldal használatához nélkülözhetetlen sütik",
                value: "essential",
                description: "These are cookies that are essential for the website to work correctly.",
                checked: true,
            },
            {
                type: "Statisztikai célú sütik",
                value: "analytics",
                description: "Ezek a sütik az oldal tulajdonosát segítik abban, hogy mélyebben megértsék a felhasználói interakciókat. Az ezek által gyűjtött adatok anonimizáltak, a felhasználók személyes beazonosítására nem alkalmasak.",
                checked: true,
            }
        ],
    });

    var readCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    // Check cookies on page-load
    let cookiePrefs = readCookie('cookieControlPrefs');
    let initializedAnalytics = false;
    if (cookiePrefs && cookiePrefs.includes("analytics")) {
        // Initialize analytics
        window.dataLayer = window.dataLayer || [];

        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', ga_id);
        initializedAnalytics = true;
        console.log("Initialized analytics: " + ga_id);
    }

    // Check cookies when closing popup
    $("body").on("gdpr:accept", function() {
        let cookiePrefs = readCookie('cookieControlPrefs');
        if (cookiePrefs && cookiePrefs.includes("analytics") && !initializedAnalytics) {
            // Initialize analytics
            window.dataLayer = window.dataLayer || [];

            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', ga_id);
            initializedAnalytics = true;
            console.log("Initialized analytics: " + ga_id);
        }
    });


});